import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import cn from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Input from '@material-ui/core/Input';
import IconButton from '@material-ui/core/IconButton';

import Icon from 'components/Icon';
import styles from './styles';

class FiltersInput extends PureComponent {
  static propTypes = {
    classes: PropTypes.shape().isRequired,
    startIcon: PropTypes.string,
    endIcon: PropTypes.string,
    placeholder: PropTypes.string,
    autoFocus: PropTypes.bool,
    onChangeInput: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    large: PropTypes.bool,
    clearable: PropTypes.bool,
    onClear: PropTypes.func,
    ariaLabel: PropTypes.string,
  };

  static defaultProps = {
    startIcon: '',
    endIcon: '',
    placeholder: 'Search',
    autoFocus: false,
    large: false,
    clearable: false,
    onClear: null,
    ariaLabel: null,
  };

  inputEndAdornment = node => {
    return <InputAdornment position="end">{node}</InputAdornment>;
  };

  handleChange = event => {
    this.props.onChangeInput(event.target.value);
  };

  handleClear = () => {
    this.props.onChangeInput('');
    this.input?.focus(); // focused clear button disappears, move focus to reasonable spot

    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  handleKeyDown = event => {
    event.stopPropagation();
  };

  renderClearButton = () => {
    const { classes } = this.props;

    return this.inputEndAdornment(
      <IconButton aria-label="Clear Search Terms" onClick={this.handleClear} className={classes.iconButton}>
        <Icon icon="clearSearch" className={classes.clearIcon} width="10" height="10" />
      </IconButton>,
    );
  };

  renderEndAdornment = () => {
    const { clearable, endIcon, value } = this.props;

    if (clearable && !isEmpty(value)) {
      return this.renderClearButton();
    }

    return endIcon && this.inputEndAdornment(<Icon icon={endIcon} />);
  };

  render() {
    const { classes, startIcon, endIcon, placeholder, large, clearable, ariaLabel, ...rest } = this.props;
    return (
      <Input
        className={cn(classes.root, { [classes.large]: large })}
        inputRef={ref => {
          this.input = ref;
        }}
        startAdornment={
          startIcon && (
            <InputAdornment position="start">
              <Icon icon={startIcon} className={classes.searchIcon} aria-hidden />
            </InputAdornment>
          )
        }
        endAdornment={this.renderEndAdornment()}
        disableUnderline
        fullWidth
        placeholder={placeholder}
        onChange={this.handleChange}
        onKeyDown={this.handleKeyDown}
        inputProps={{ 'aria-label': ariaLabel || placeholder, autoFocus: this.props.autoFocus }}
        {...rest}
      />
    );
  }
}

export default withStyles(styles)(FiltersInput);
